import WelcomeSection from "../components/HomeComponents/WelcomeSectionComponent";
import WhyUsSection from "../components/HomeComponents/WhyUsComponent";
import AboutSection from "../components/HomeComponents/AboutComponent";
import OurValuesSection from "../components/HomeComponents/OurValuesComponent";
import OurServicesSection from "../components/HomeComponents/OurServicesComponent";
import TestimonialsSection from "../components/HomeComponents/TestimonialsComponent";
import FaqSection from "../components/HomeComponents/FaqComponent";
import SendMessageContainer from "../containers/SendMessageContainer";
import { Helmet } from "react-helmet-async";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>
          BeYou Corporate | Premium Corporate Attire & Custom Uniforms
        </title>
      </Helmet>
      <WelcomeSection />
      <WhyUsSection />
      <AboutSection />
      <OurValuesSection />
      <OurServicesSection />
      <TestimonialsSection />
      <FaqSection />
      <SendMessageContainer />
    </>
  );
}

export default HomePage;
