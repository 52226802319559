import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  faWhatsapp,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
export default function FooterSection() {
  return (
    <Container
      fluid
      style={{ backgroundColor: " #a09e9d" }}
      className="text-white footer pt-5"
    >
      <Container className="py-5">
        <Row className="g-5">
          <Col lg={6} className="wow fadeIn" data-wow-delay="0.1s">
            <a href="index.html" className="d-inline-block mb-3">
              <img
                src="../assets/images/logos/BeyouCorporateLogo3.png"
                width="200px"
              />
            </a>
            <p className="mb-0">
              Dressing Your Team for Success in Every Stitch BeYou Corporate
              pioneers in curating bespoke uniform solutions for businesses both
              large and small. We believe a uniform is not just apparel—it's a
              statement, a brand ambassador, and a testament to your company's
              ethos. With every thread, we weave quality, durability, and your
              brand's unique signature to ensure your team always puts its best
              foot forward.
            </p>
          </Col>
          <Col lg={6} className="wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <a className="text-white links-under" href="tel:+971504217196">
              <p>
                <FontAwesomeIcon className="me-3 " icon={faPhone} />
                +971 50 421 7196
              </p>
            </a>
            <a
              className="text-white links-under"
              href="mailto:info@beyoucorporate.com"
            >
              <p>
                <FontAwesomeIcon className="me-3 " icon={faEnvelope} />
                info@beyoucorporate.com
              </p>
            </a>

            <a
              className="text-white links-under"
              target="_blank"
              href="https://wa.me/971504217196"
            >
              <p>
                <FontAwesomeIcon icon={faWhatsapp} className="me-3" />
                +971 50 421 7196
              </p>
            </a>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61552513171678"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                target="_blank"
                href="https://www.instagram.com/beyoucorporate/"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="wow fadeIn" data-wow-delay="0.1s">
        <div className="copyright">
          <Row>
            <Col lg={12} className="justify-content-center d-flex">
              &copy; BeYou Corporate, All Right Reserved.
            </Col>
          </Row>
        </div>
      </Container>
    </Container>
  );
}
