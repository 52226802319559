import * as yup from "yup";
import ContactSection from "../components/HomeComponents/ContactComponent";
import { useFormik } from "formik";
import emailjs from "emailjs-com";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  subject: yup.string().required(),
  phone: yup.number(),
  message: yup.string().required(),
});

function SendMessageContainer() {
  const emailService = "service_60mwjgn";
  const templateId = "template_1bn9wmm";

  const { values, handleChange, errors, handleSubmit, setValues } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
      recaptcha: "",
      success: false,
      status: "none",
    },
    onSubmit: async (values: any) => {
      if (values.recaptcha === "") {
        alert("Please check reCAPTCHA");
      } else if (values.recaptcha === "expired") {
        alert("reCAPTCHA Expired");
      } else {
        setValues({ ...values, status: "loading" });
        const emailParams = {
          name: values.name,
          email: values.email,
          subject: values.subject,
          phone: values.phone,
          message: values.message,
          recaptcha: values.recaptcha,
        };
        try {
          await emailjs.send(
            emailService,
            templateId,
            emailParams,
            "XAXEsJJA6rgtY6jGN"
          );
          setValues({ ...values, status: "sent", recaptcha: "expired" });
        } catch (error) {
          console.error("Email could not be sent:", error);
        }
      }
    },

    validationSchema,
  });

  const props = {
    values,
    handleChange,
    errors,
    handleSubmit,
  };

  return <ContactSection {...props} />;
}

export default SendMessageContainer;
