import ResponsiveDrawer from "../sections/layouts/drawer";
// import ScrollToTopButton from "../utils/ScrollToTopButton";
import WhatsappButton from "../utils/WhatsappButton";
import { HelmetProvider } from "react-helmet-async";

function NavbarContainer() {
  return (
    <HelmetProvider>
      <WhatsappButton />
      {/* <ScrollToTopButton /> */}
      <ResponsiveDrawer />
    </HelmetProvider>
  );
}

export default NavbarContainer;
