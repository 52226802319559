import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { scrollToSection } from "../../utils/ScrollToSection";

export default function WelcomeSection() {
  return (
    <Container id="home" fluid className="hero-header mb-0 pt-5">
      <Container className="pt-5">
        <Row className="pt-5">
          <Col
            className="align-self-center text-center text-lg-start mb-lg-5"
            lg={6}
          >
            <h1 className="display-4 text-white mb-4 animated slideInRight">
              Welcome to BeYou Corporate
            </h1>
            <p
              style={{ fontSize: "20px" }}
              className="text-white mb-4 animated slideInRight"
            >
              BeYou Corporate is here to help startups cut costs without losing
              quality. <br /> We offer wide range of uniforms and customized
              Shirts (with no minimum order), Yes you read that right, you can
              order from 1 piece to ∞. <br />
              We Got You Covered
            </p>
            <a
              onClick={() => {
                scrollToSection("contact");
              }}
              className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
            >
              Contact Us
            </a>
          </Col>
          <Col
            className="align-self-end text-center text-lg-end animated fadeIn home-shirt"
            lg={6}
          >
            <img
              className="img-fluid"
              src="../assets/images/shirts/whiteShirt.png"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
