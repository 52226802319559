import "../styles/ScrollToTopButton.css";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhatsappButton = () => {
  return (
    <a href="https://wa.me/971504217196" className="float" target="_blank">
      <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
    </a>
  );
};

export default WhatsappButton;
