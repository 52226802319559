import React from "react";
import "./index.css";
import { AppRoutes } from "./navigation/Routes";
import ReactDOM from "react-dom";

ReactDOM.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
  document.getElementById("root")
);
