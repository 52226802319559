import { Box } from "@mui/material";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Outlet } from "react-router-dom";
import $ from "jquery";
import { useEffect } from "react";
import FooterSection from "../../components/FooterComponent";
import { scrollToSection } from "../../utils/ScrollToSection";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ResponsiveDrawer() {
  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 300) {
        $(".sticky-top")
          .addClass("bg-primary-c shadow-sm ")
          .removeClass("bg-transparent")
          .css("top", "0px");
      } else {
        $(".sticky-top")
          .addClass("bg-transparent")
          .removeClass("bg-primary-c shadow-sm ")
          .css("top", "-150px");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary sticky-top bg-transparent"
      >
        <Container>
          <Navbar.Brand href="../">
            <img
              src="../assets/images/logos/BeyouCorporateLogo3.png"
              width="200px"
            />
          </Navbar.Brand>
          {/* <div className="wts">
            <a
              className="text-white links-under"
              target="_blank"
              href="https://wa.me/971504217196"
            >
              <FontAwesomeIcon
                style={{
                  marginTop: "13px !important",
                  fontSize: "26px",
                  color: "black",
                }}
                icon={faWhatsapp}
                className="me-3"
              />
            </a>
            <a className="text-white links-under" href="tel:+971504217196">
              <FontAwesomeIcon
                style={{
                  marginTop: "13px !important",
                  fontSize: "20px",
                  color: "black",
                }}
                icon={faPhone}
                className="me-0"
              />
            </a>
          </div> */}

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link
                onClick={() => {
                  scrollToSection("home");
                }}
              >
                Home
              </Nav.Link>
              <NavDropdown title="About" id="collapsible-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => {
                    scrollToSection("whyus");
                  }}
                >
                  Why Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    scrollToSection("about");
                  }}
                >
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    scrollToSection("values");
                  }}
                >
                  Our Values
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link
                onClick={() => {
                  scrollToSection("services");
                }}
              >
                Services
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  scrollToSection("contact");
                }}
              >
                Contact
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="../assets/BeYou-Company profile.pdf"
              >
                Company Profile
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Box
        component="main"
        sx={{
          marginTop: "-75px !important",
          flexGrow: 1,
          p: 0,
        }}
      >
        <Outlet />
        <FooterSection />
      </Box>
    </>
  );
}

export default ResponsiveDrawer;
