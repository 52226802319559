import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function FirstSection() {
  return (
    <Container fluid className="pt-5 bg-primary hero-header">
      <Container className="pt-5">
        <Row className="g-5 pt-5">
          <Col
            lg={6}
            className="align-self-center text-center text-lg-start mb-lg-5"
          >
            <h1 className="display-4 text-white mb-4 animated slideInRight">
              404 Error
            </h1>
          </Col>
          <Col lg={6} className="align-self-end text-center text-lg-end"></Col>
        </Row>
      </Container>
    </Container>
  );
}
