import { Alert, FloatingLabel } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ReCAPTCHA from "react-google-recaptcha";

function ContactSection({ values, handleChange, errors, handleSubmit }: any) {
  const handleRecaptcha = (response: any) => {
    handleChange("recaptcha")(response);
  };

  return (
    <>
      <Col className="py-5 values" fluid id="contact">
        <Col className="py-5">
          <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s">
            <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
          </div>

          <Col lg={7} className="mx-auto text-center p-3">
            <Form>
              <Row className="g-2">
                <Col md={6}>
                  <FloatingLabel label="Your Name">
                    <Form.Control
                      defaultValue={values.name}
                      onChange={handleChange("name")}
                      type="text"
                      id="name"
                      className="form-control"
                      isInvalid={!!errors.name}
                      placeholder="Your Name"
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel label="Your Email">
                    <Form.Control
                      defaultValue={values.email}
                      onChange={handleChange("email")}
                      className="form-control"
                      placeholder="Your Email"
                      id="email"
                      isInvalid={!!errors.email}
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel label="Subject">
                    <Form.Control
                      defaultValue={values.subject}
                      onChange={handleChange("subject")}
                      className="form-control"
                      placeholder="Subject"
                      id="subject"
                      isInvalid={!!errors.subject}
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <PhoneInput
                    country={"ae"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={values.phone}
                    onChange={handleChange("phone")}
                  />
                </Col>
                <Col md={12}>
                  <FloatingLabel label="Message">
                    <Form.Control
                      as="textarea"
                      className="form-control"
                      placeholder="Leave a message here"
                      id="message"
                      defaultValue={values.message}
                      onChange={handleChange("message")}
                      style={{ height: "150px" }}
                      isInvalid={!!errors.message}
                      required
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <ReCAPTCHA
                className="py-2"
                sitekey="6LfxwqYoAAAAADCBud1GJyK0_OrbsdECt-omvFOF" // Replace with your reCAPTCHA site key
                onChange={handleRecaptcha}
              />
              <div>
                {values.status === "none" && (
                  <Button
                    style={{
                      backgroundColor: "#a09e9d",
                      borderColor: "#a09e9d",
                    }}
                    className="btn btn-primary w-100 py-3 submit-btn"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </Button>
                )}
                {values.status === "loading" && (
                  <Alert className="sent-message mt-3" variant="success">
                    Your message has been sent. Thank you!
                  </Alert>
                )}
                {values.status === "sent" && (
                  <Button
                    style={{
                      backgroundColor: "#a09e9d",
                      borderColor: "#a09e9d",
                    }}
                    className="btn btn-primary w-100 py-3 submit-btn"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Col>
      </Col>
    </>
  );
}

export default ContactSection;
